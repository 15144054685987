const CompanyOwnerDetailsIcon = () => {
  return (
    <svg
      width="35"
      height="39"
      viewBox="0 0 35 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4716 25.3333C13.7748 25.3333 12.1632 24.9635 10.7119 24.2993C9.70632 23.839 9.2035 23.6088 8.92435 23.5293C8.63419 23.4466 8.46605 23.4167 8.1654 23.3939C7.87614 23.3722 7.6596 23.3889 7.2265 23.4223C7.04935 23.4359 6.89308 23.4552 6.74505 23.4822C4.37566 23.9156 2.52129 25.7891 2.09234 28.1831C2 28.6984 2 29.3165 2 30.5524V33.8889C2 34.978 2 35.5224 2.20975 35.9383C2.39428 36.3043 2.68869 36.6016 3.0508 36.7881C3.46248 37 4.0014 37 5.07923 37H10.4679M23.1697 9.77778C23.1697 14.0733 19.7231 17.5556 15.4716 17.5556C11.2201 17.5556 7.77355 14.0733 7.77355 9.77778C7.77355 5.48223 11.2201 2 15.4716 2C19.7231 2 23.1697 5.48223 23.1697 9.77778ZM18.5312 37L22.4284 36.2125C22.7682 36.1439 22.9382 36.1094 23.0965 36.0466C23.2372 35.991 23.3708 35.9187 23.4947 35.8314C23.6343 35.733 23.7568 35.6093 24.0018 35.3616L32.0028 27.2778C33.0657 26.2039 33.0657 24.4628 32.0028 23.3889C30.9401 22.315 29.2167 22.315 28.1538 23.3889L20.1528 31.4727C19.9078 31.7204 19.7852 31.8441 19.6878 31.9853C19.6014 32.1103 19.53 32.2454 19.4748 32.3876C19.4126 32.5476 19.3788 32.7193 19.3106 33.0625L18.5312 37Z"
        stroke="#4D4D4D"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CompanyOwnerDetailsIcon;
