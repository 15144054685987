export const columns: any = [
  { Header: "Event Name", accessor: "eventName" },
  { Header: "Commenced By", accessor: "commencedBy" },
  { Header: "Date", accessor: "date" },
  { Header: "Event Value", accessor: "eventValue" },
  { Header: "Event Direction", accessor: "eventDirection" },
  { Header: "Status", accessor: "status" },
];
export const data = [
  {
    eventName: "Reward",
    commencedBy: "Techvalens Software systems Pvt. Ltd.",
    date: "10 July 2024",
    eventValue: "5000 $",
    eventDirection: "Credit",
    status: "Success",
  },
  {
    eventName: "Purchase",
    commencedBy: "Global Electronics Ltd.",
    date: "15 July 2024",
    eventValue: "1200 $",
    eventDirection: "Debit",
    status: "Pending",
  },
  {
    eventName: "Refund",
    commencedBy: "Techvalens Software systems Pvt. Ltd.",
    date: "20 July 2024",
    eventValue: "300 $",
    eventDirection: "Credit",
    status: "Success",
  },
  {
    eventName: "Subscription",
    commencedBy: "MediaStream Inc.",
    date: "25 July 2024",
    eventValue: "50 $",
    eventDirection: "Debit",
    status: "Success",
  },
  {
    eventName: "Payment",
    commencedBy: "Techvalens Software systems Pvt. Ltd.",
    date: "30 July 2024",
    eventValue: "800 $",
    eventDirection: "Debit",
    status: "Failed",
  },
  {
    eventName: "Bonus",
    commencedBy: "Alpha Tech Solutions",
    date: "05 August 2024",
    eventValue: "1000 $",
    eventDirection: "Credit",
    status: "Success",
  },
  {
    eventName: "Fee",
    commencedBy: "Beta Innovations LLC",
    date: "10 August 2024",
    eventValue: "200 $",
    eventDirection: "Debit",
    status: "Success",
  },
  {
    eventName: "Dividend",
    commencedBy: "Gamma Investments",
    date: "12 August 2024",
    eventValue: "1500 $",
    eventDirection: "Credit",
    status: "Pending",
  },
  {
    eventName: "Penalty",
    commencedBy: "Delta Enterprises",
    date: "18 August 2024",
    eventValue: "400 $",
    eventDirection: "Debit",
    status: "Success",
  },
  {
    eventName: "Transfer",
    commencedBy: "Epsilon Corp.",
    date: "22 August 2024",
    eventValue: "2500 $",
    eventDirection: "Debit",
    status: "Success",
  },
];
export const detailHeading = [
  "Main Balance",
  "Reward Balance",
  "Tax",
  "Burned amount",
  "Payment Method",
  "Packages",
];
export const detailValue = [
  { mainBalance: "4000 $" },
  { rewardBalance: "245 $" },
  { tax: "10%" },
  { burnedAmount: "200 $" },
  { paymentMethod: "UPI" },
  { packages: "Dummy" },
];
