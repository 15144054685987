import { TemplateSearch } from "ui-components";
import Table from "../../../components/Table/Table";
import { columns, data, detailHeading, detailValue } from "./AccountData";
import { useTranslation } from "react-i18next";
import "./Account.scss"
const Accounts = () => {
  const { t } = useTranslation()
  return (
    <div className="account-container">
      <div className="header-row">
        <span>Techvalens</span>
        <span>Total Balance: 50,000$</span>
      </div>
      <div className="search-bar">
        <TemplateSearch
          placeholder={t("GENERAL.SEARCH_ASSESSMENT")}
          iconName="searchList"
          onChange={() => { }}
        />
      </div>
      <div className="accounts-table">
        <Table columns={columns} data={data} detailHeading={detailHeading} detailValue={detailValue} />
      </div>
    </div>
  );
};

export default Accounts;
