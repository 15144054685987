const CompanyCalenderIcon = () => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 20.8889V24.6667H24.6667"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 13.3333V34.1111C2 34.6121 2.19901 35.0925 2.55324 35.4468C2.90748 35.801 3.38792 36 3.88889 36H34.1111C34.6121 36 35.0925 35.801 35.4468 35.4468C35.801 35.0925 36 34.6121 36 34.1111V13.3333M2 13.3333H36M2 13.3333V5.77778C2 5.27681 2.19901 4.79637 2.55324 4.44213C2.90748 4.0879 3.38792 3.88889 3.88889 3.88889H34.1111C34.6121 3.88889 35.0925 4.0879 35.4468 4.44213C35.801 4.79637 36 5.27681 36 5.77778V13.3333M26.5556 2V7.66667M11.4444 2V7.66667M13.3333 28.4444V20.8889M24.6667 20.8889V28.4444"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CompanyCalenderIcon;
