import { AssessmentButton } from "ui-components";
import CustomModal from "../../../components/CustomModal/CustomModal";
import "./ConfirmPopup.scss";
import { useTranslation } from "react-i18next";
import CircleCrossIcon from "../../../utils/Images/CircleCrossIcon";
import DeleteAssessmentListIcon from "../../../utils/Images/DeleteAssessmentListIcon";

export interface IExperienceLeftSideProps {
  showDelete: boolean;
  label: string;
  yesCallback: any;
  noCallback: any;
  isCrossBtn?: boolean;
  handleClose?: () => void;
}

const ConfirmPopup: React.FunctionComponent<IExperienceLeftSideProps> = ({
  showDelete,
  label,
  yesCallback,
  noCallback,
  isCrossBtn = false,
  handleClose = () => {},
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <CustomModal
        show={showDelete}
        isHeaderVisible={false}
        isFooterVisible={false}
        className="confirmPopup"
      >
        {isCrossBtn && (
          <div onClick={handleClose} className="popupCrossIcon">
            <CircleCrossIcon />
          </div>
        )}
        <div className="deleteData">
          <div className="noListFound">
            <div className="text-center">
              <DeleteAssessmentListIcon />
              <h3>{label}</h3>
              <div className="deleteBtnSec">
                <AssessmentButton className="lightBtn" onClick={yesCallback}>
                  {t("GENERAL.YES")}
                </AssessmentButton>
                <AssessmentButton className="lightBtn" onClick={noCallback}>
                  {t("GENERAL.NO")}
                </AssessmentButton>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default ConfirmPopup;
