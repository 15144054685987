import React from "react";
import "./Sample.scss";
import UserImage from "../../utils/Images/UserImage";

const Sample = () => {
  return (
    <div className="sampleWrapper">
      <div className="container-fluid mt-4 mb-4">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-2 text-center ">
                    <div className="companyBg">
                      <div className="profileSec">
                      <UserImage />
                      <h3>Test</h3>
                      <p>Test</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-10"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sample;
