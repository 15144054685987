import "./AssessmentsList.scss";
// import UserImage from "../../utils/Images/UserImage";
import { useEffect, useRef, useState } from "react";
import { companyGetAssessments } from "../../service/Assessment/assessment";
import { Pagination, showToast, TemplateSearch } from "ui-components";
import AssessmentListingIcon from "../../utils/Images/AssessmentListingIcon";
import { useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { convertDate } from "../../utils/Common/Constant";
import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable";
import BackArrow from "../../utils/Images/BackArrow";

const AssessmentsList = () => {
  const [AssessmentsList, setAssessmentsList] = useState<any>([]);
  const { companyId } = useParams();
  const { t } = useTranslation();
  const location = useLocation()
  const [searchText, setSearchText] = useState("")
  const { companyName } = location.state
  const [totalQuestions, setTotalQuestions] = useState("")
  const [pageSize, setPageSize] = useState(10);
  // const [searchParams] = useSearchParams();
  // const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
  const AssessListPaginationRef = useRef<any>(null)
  const [page, setPage] = useState(1)
  const postData = {
    companyId: companyId,
    search: searchText,
    filterOptions: {
      status: "",
      hasResponse: "both",
      reviewPending: "both",
      selected: "both",
    },
    currentPage: page,
    pageSize: 10,
  };
  useEffect(() => {
    companyGetAssessmentsHandler(postData);
  }, [searchText, page]);

  async function companyGetAssessmentsHandler(postData: unknown) {
    const response = await companyGetAssessments(postData);
    if (response?.status === 200) {
      setTotalQuestions(response?.data?.totalCount)
      setAssessmentsList(response?.data?.data);
    } else {
      showToast(response?.data?.customMessage, "error");
    }
  }
  useEffect(() => {
    if (AssessListPaginationRef.current && managePaginationRef != null) {
      AssessListPaginationRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [managePaginationRef]);
  return (
    <>
      <div className="AssessmentsListWrapper">
        <div className="container-fluid mt-4 mb-4">
          <div className="col-md-12 ">
            <div className="companyNameTitle">
              <div className="backArrow" onClick={() => window.history.back()}>
                <BackArrow />
              </div>
              <span>{companyName}</span>
            </div>
          </div>
          <div className="col-md-12">
            <div
              className={"AssessmentListSearchSec "}
              ref={AssessListPaginationRef}
            >

              <TemplateSearch
                onChange={(text) => {
                  setPage(1)
                  setSearchText(text)
                }}
                placeholder={t("GENERAL.SEARCH_ASSESSMENT")}
                iconName="searchList"
              />
            </div>
          </div>
          <div className="row mt-5">
            <div className="totalAssess">
              <span>{t("CREATED_ASSESSMENT_LIST_AND_REVIEW.TOTAL_ASSESSMENTS")} : {totalQuestions}</span>
            </div>
            {
              AssessmentsList?.length ?
                AssessmentsList.map((assessment: any) => {
                  return (
                    <div className="AssessmentListContentSec" key={Math.random()}>
                      <div className="AssessmentListCard">
                        <div className="AssessmentListCardImg ">
                          <AssessmentListingIcon />
                          <h4 className="text-center">{assessment?.status}</h4>{" "}
                          <h6>
                            {convertDate(AssessmentsList?.createdAt, true) ?? "-"}
                          </h6>
                        </div>
                        <div className="AssessmentListCardDetails">
                          <div className="ListCardDetailsTitleSec">
                            <div className="ListCardDetailsTitle">
                              <div className="listItemSec">
                                <div className="ListCardTitleHeading">
                                  <div>
                                    <h3
                                      title="Hiring assessment invite flow-1"
                                      className="cursorPointer"
                                    >
                                      {assessment?.title}
                                    </h3>
                                    <b>
                                      <p dangerouslySetInnerHTML={{
                                        __html: `${assessment?.description}`,
                                      }}>
                                      </p>
                                    </b>

                                    <p>{assessment?.website}</p>
                                  </div>
                                </div>
                              </div>
                              <div className="ListContentSkillsSec">
                                <div className="ListContentSkills decreaseGap">
                                  <div className="w-100">
                                    <div className="w-100">
                                      <p>{t("GENERAL.TOTAL_APPLICANTS")}</p>
                                      <h5>
                                        {assessment?.totalApplicaitons ?? "-"}
                                      </h5>
                                    </div>
                                    <div>
                                      <p>{t("GENERAL.TOTAL_RESPONSES")}</p>
                                      <h5>{assessment?.totalResponses ?? "-"}</h5>
                                    </div>
                                  </div>
                                  <div className="w-100">
                                    <div className="w-100">
                                      <p>{t("GENERAL.CREATED_BY")}</p>
                                      <h5>{assessment?.createdBy ?? "-"}</h5>
                                    </div>
                                  </div>
                                  <div className="w-100">
                                    <div className="w-100">
                                      <p>{t("GENERAL.RETURN_REWARDS")}</p>
                                      <h5>{assessment?.returnRewards ?? "-"}</h5>
                                    </div>
                                  </div>
                                  <div className="w-100">
                                    <div className="w-100">
                                      <p>{t("GENERAL.EARNING")}</p>
                                      <h5>{assessment?.cost ?? "-"}</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );

                }

                )
                :
                <div>
                  <DataNotAvailable />
                </div>}
          </div>
          <div className="row">
            <div className="col-md-12">
              <Pagination
                className="pagination-bar"
                currentPage={page ?? 1}
                totalCount={totalQuestions || 0}
                pageSize={pageSize}
                onPageChange={(page: any) => {
                  setPage(page)
                  setManagePaginationRef(Math.random());
                }}
                ofLabel={t("GENERAL.OF")}
                resultLabel={t("GENERAL.RESULTS")}
                setPageSize={setPageSize}
              />
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default AssessmentsList;
