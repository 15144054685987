import { TemplateSearch } from "ui-components";
import { useTranslation } from "react-i18next";
import "./PaymentScreen.scss"
import { columns, data, detailHeading, detailValue } from "../CompanyListRoutes/Accounts/AccountData";
import Table from "../../components/Table/Table";
const PaymentScreen = () => {
    const { t } = useTranslation()
    return (
        <div className="payment-container">
            <div className="header-row">
                <span>Techvalens</span>
                <span>Total Balance: 50,000$</span>
            </div>
            <div className="search-bar">
                <TemplateSearch
                    placeholder={t("GENERAL.SEARCH_ASSESSMENT")}
                    iconName="searchList"
                    onChange={() => { }}
                />
            </div>
            <div className="payment-table">
                <Table columns={columns} data={data} detailHeading={detailHeading} detailValue={detailValue} />
            </div>
        </div>
    );
};

export default PaymentScreen;
