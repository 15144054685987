import apiClient from "../Connection/Connection";

//  to get created assessment list
export const getAssessmentDetails = async (assessmentId: string | number) => {
  try {
    const res = await apiClient.get(
      `inviteApplicant/companyGetAssessmentDetailsWithSlug?assessmentId=${assessmentId}`,
    );
    return res?.data;
  } catch (error) {
    console.log("error", error);
  }
};

//  to get Recommendation list
export const getRecommendationDetails = async (
  assessmentId: string | number,
  currentPage: number,
  pageSize: number,
) => {
  try {
    const res = await apiClient.get(
      `inviteApplicant/recommendedApplicants?assessmentId=${assessmentId}&currentPage=${currentPage}&pageSize=${pageSize}`,
    );
    return res?.data;
  } catch (error) {
    console.log("error", error);
  }
};

//  to get created assessment list
export const sendInvitationApi = async (data: any) => {
  try {
    const res = await apiClient.post("inviteApplicant/inviteAnApplicant", data);
    return res;
  } catch (error) {
    console.log("error", error);
  }
};

//  to get GetInvitedApplicantList list
export const getInvitedApplicantList = async (
  assessmentId: string | number | undefined,
  currentPage: number,
  pageSize: number,
  search: string,
) => {
  try {
    const res = await apiClient.get(
      `inviteApplicant/companyGetInvitedApplicantList?assessmentId=${assessmentId}&search=${search}&currentPage=${currentPage}&pageSize=${pageSize}`,
    );
    return res?.data;
  } catch (error) {
    console.log("error", error);
  }
};

//  to validate Assessment Applicant
export const validateAssessmentApplicant = async (
  assessmentSlug: string | number | undefined | null,
  applicantId: string | number | undefined | null,
  token: string | undefined | null,
) => {
  try {
    const res = await apiClient.get(
      `assessmentApplicant/validateAssessmentApplicant?assessmentSlug=${assessmentSlug}&applicantId=${applicantId}&token=${token}`,
    );
    return res?.data;
  } catch (error) {
    console.log("error", error);
  }
};

//  to resend Invite An Applicant
export const resendInviteAnApplicant = async (data: any) => {
  try {
    const res = await apiClient.post(
      "inviteApplicant/resendInviteAnApplicant",
      data,
    );
    return res;
  } catch (error) {
    console.log("error", error);
  }
};
