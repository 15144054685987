import "./App.css";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { extractLanguage } from "./utils/Common/Constant";
import { useEffect } from "react";
import i18n from "./i18nConfig";
import Login from "./pages/Auth/Login";
import Sample from "./pages/Sample/Sample";
// import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Invite from "./pages/Invite/Invite";
import CompanyList from "./pages/Company/CompanyList";
import CreateTicket from "./pages/SupportTickets/CreateTicket";
import AssessmentsList from "./pages/Assessments/AssessmentsList";
import CreatedAssessmentList from "./pages/CreatedAssessmentList/CreatedAssessmentList";
import CompanyProfile from "./pages/Company/CompanyProfile";
import ListTicket from "./pages/SupportTickets/ListTicket";
import ViewTicket from "./pages/SupportTickets/ViewTicket";
import OpenTickets from "./pages/CompanyListRoutes/OpenTickets/OpenTickets";
import {
  PrivateAdminRoute,
  PublicAdminRoute,
} from "./utils/AuthRoutes/AuthRoutes";
import NotFound from "./pages/NotFound/NotFound";
import Accounts from "./pages/CompanyListRoutes/Accounts/Accounts";
import Footer from "./components/Footer/Footer";
import PaymentScreen from "./pages/Payments/PaymentScreen";
import FeedbackScreen from "./pages/Feedback/FeedbackScreen";
import ReportedQuestionScreen from "./pages/ReportedQuestions/ReportedQuestionScreen";
// import Sidebar from "./components/Sidebar/Sidebar";

function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    const language: any = extractLanguage(pathname);
    i18n.changeLanguage(language);
  }, [pathname]);

  const showHeader = !location.pathname.includes("Login");

  return (
    <div className="App">
      {/* <Sidebar /> */}
      {/* <Header /> */}

      {showHeader && <Header />}

      <Routes>
        <Route path="/" element={<Navigate to={"/en/Login"} replace />} />
        <Route
          path="/:languageParam/Login"
          element={
            <PublicAdminRoute>
              <Login />
            </PublicAdminRoute>
          }
        />
        <Route path="/:languageParam/sample" element={<Sample />} />
        <Route path="/:languageParam/invite" element={<Invite />} />
        <Route
          path="/:languageParam/company_list"
          element={
            <PrivateAdminRoute>
              <CompanyList />
            </PrivateAdminRoute>
          }
        />
        <Route
          path="/:languageParam/company_list/openTickets"
          element={
            <PrivateAdminRoute>
              <OpenTickets />
            </PrivateAdminRoute>
          }
        />
        <Route
          path="/:languageParam/company_list/accounts"
          element={
            <PrivateAdminRoute>
              <Accounts />
            </PrivateAdminRoute>
          }
        />

        <Route
          path="/:languageParam/companyProfile/:companyId"
          element={
            <PrivateAdminRoute>
              <CompanyProfile />
            </PrivateAdminRoute>
          }
        />

        <Route
          path="/:languageParam/assessment_list/:companyId"
          element={
            <PrivateAdminRoute>
              <AssessmentsList />
            </PrivateAdminRoute>
          }
        />
        <Route
          path="/:languageParam/ticket/create"
          element={
            <PrivateAdminRoute>
              <CreateTicket />
            </PrivateAdminRoute>
          }
        />
        <Route
          path="/:languageParam/assessment/list"
          element={
            <PrivateAdminRoute>
              <CreatedAssessmentList />
            </PrivateAdminRoute>
          }
        />
        <Route
          path="/:languageParam/ticket/list"
          element={
            <PrivateAdminRoute>
              <ListTicket />
            </PrivateAdminRoute>
          }
        />
        <Route
          path="/:languageParam/ticket/:ticketId"
          element={
            <PrivateAdminRoute>
              <ViewTicket />
            </PrivateAdminRoute>
          }
        />
        <Route
          path="/:languageParam/payment"
          element={
            <PrivateAdminRoute>
              <PaymentScreen />
            </PrivateAdminRoute>
          }
        />
        <Route
          path="/:languageParam/ticket/list"
          element={
            <PrivateAdminRoute>
              <ListTicket />
            </PrivateAdminRoute>
          }
        />
        <Route
          path="/:languageParam/reported_questions"
          element={
            <PrivateAdminRoute>
              <ReportedQuestionScreen />
            </PrivateAdminRoute>
          }
        />
        <Route
          path="/:languageParam/feedback"
          element={
            <PrivateAdminRoute>
              <FeedbackScreen />
            </PrivateAdminRoute>
          }
        />
        <Route path="/:languageParam/*" element={<NotFound />} />

        {/* ListingFilter */}
        {/* <Route path="*" element={<Navigate to={"/en/login"} replace />} /> */}
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
