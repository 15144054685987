const CompanyLocationIcon = () => {
  return (
    <svg
      width="29"
      height="35"
      viewBox="0 0 29 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0506 31.0985C14.803 31.2812 14.5721 31.4471 14.3612 31.5959C14.1361 31.4457 13.8866 31.2731 13.6159 31.0784C12.3397 30.1601 10.6846 28.812 9.05453 27.1306C5.71111 23.6818 2.9 19.2794 2.9 14.5833C2.9 8.14001 8.09349 2.91667 14.5 2.91667C20.9065 2.91667 26.1 8.14001 26.1 14.5833C26.1 19.229 23.165 23.6188 19.6993 27.1042C18.0179 28.7955 16.3258 30.1581 15.0506 31.0985ZM29 14.5833C29 26.25 14.5 35 14.5 35C13.775 35 0 26.25 0 14.5833C0 6.52917 6.49186 0 14.5 0C22.5081 0 29 6.52917 29 14.5833ZM17.4 14.5833C17.4 16.1941 16.1016 17.5 14.5 17.5C12.8984 17.5 11.6 16.1941 11.6 14.5833C11.6 12.9725 12.8984 11.6667 14.5 11.6667C16.1016 11.6667 17.4 12.9725 17.4 14.5833ZM20.3 14.5833C20.3 17.8051 17.7033 20.4167 14.5 20.4167C11.2967 20.4167 8.7 17.8051 8.7 14.5833C8.7 11.3617 11.2967 8.75 14.5 8.75C17.7033 8.75 20.3 11.3617 20.3 14.5833Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default CompanyLocationIcon;
