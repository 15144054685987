import { useEffect, useRef, useState } from "react";
import {
  AssessmentButton,
  Pagination,
  showToast,
  TemplateSearch,
  TypeSelect,
} from "ui-components";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { convertDate, handleKeyPressSearch } from "../../utils/Common/Constant";
import DownArrowIcon from "../../utils/Images/DownArrowIcon";
import AssessmentListingIcon from "../../utils/Images/AssessmentListingIcon";
import UpArrowIcon from "../../utils/Images/UpArrowIcon";
import "./ListTicket.scss";
import CreateTicketPopup from "./CreateTicketPopup";
import SupportTicketListingIcon from "../../utils/Images/SupportTicketListingIcon";
import { getSupportTickets, getTicketTypes } from "../../service/SupportTicket/supportTicket";
import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable";
// import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable";
const ListTicket = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const [lastActive, setLastActive] = useState(false);
  const [userCount, setUserCount] = useState(true);
  const [assessmentCount, setAssessmentCount] = useState(true);
  const [ticketCount, setTicketCount] = useState(true);
  const [lastActiveVal, setLastActiveVal] = useState("desc");
  const [userCountVal, setUserCountVal] = useState("asc");
  const [assessmentCountVal, setAssessmentCountVal] = useState("asc");
  const [ticketCountVal, setTicketCountVal] = useState("asc");

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("st");
  const searchParam = searchParams.get("search");
  const lastActiveParam = searchParams.get("lastActive");
  const userCountParam = searchParams.get("userCount");
  const assessmentCountParam = searchParams.get("assessmentCount");
  const ticketCountParam = searchParams.get("ticketCount");
  const supportTypeParam = searchParams.get("supportType");
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const timeoutRef = useRef<any>(null);
  const [ticketList, setTicketList] = useState<any>([]);
  const [showFilter] = useState(true);
  const createdAssessPaginationRef = useRef<HTMLDivElement>(null);
  const defaultStatus = [
    { label: t("GENERAL.ALL"), value: "" },
    { label: t("GENERAL.ACTIVE"), value: "Active" },
    { label: t("GENERAL.INACTIVE"), value: "Inactive" },
  ];

  const [showCreateTicket, setShowCreateTicket] = useState<boolean>(false);
  const [supportType, setSupportType] = useState([]);
  const [shouldRefresh, setShouldReferesh] = useState(false)
  useEffect(() => {
    fetchTicketTypes();
  }, []);

  const fetchTicketTypes = async () => {
    const companiesData = await getTicketTypes();
    const updateData = (companiesData?.data.data || []).map((item: any) => ({
      value: item?.supportTicketTypeId,
      label: item?.supportTicketTypeName,
    }));
    setSupportType(updateData);
  };

  const handleFilter = ({ type, value }: any) => {
    let params = {
      st: status,
      lastActive: lastActiveVal,
      userCount: userCountVal,
      assessmentCount: assessmentCountVal,
      ticketCount: ticketCountVal,
      page: page,
      search: searchParam,
      supportType: supportTypeParam,
    };

    switch (type) {
      case "status":
        params = { ...params, st: value, page: 1 };
        break;
      case "lastActive": {
        const activeFlag = !lastActive;
        const activeValue = activeFlag ? "asc" : "desc";
        setLastActive(activeFlag);
        setLastActiveVal(activeValue);
        params = { ...params, lastActive: activeValue, page: 1 };
        break;
      }
      case "userCount": {
        const activeFlag = !userCount;
        const activeValue = activeFlag ? "asc" : "desc";
        setUserCount(activeFlag);
        setUserCountVal(activeValue);
        params = { ...params, userCount: activeValue, page: 1 };
        break;
      }
      case "assessmentCount": {
        const activeFlag = !assessmentCount;
        const activeValue = activeFlag ? "asc" : "desc";
        setAssessmentCount(activeFlag);
        setAssessmentCountVal(activeValue);
        params = { ...params, assessmentCount: activeValue, page: 1 };
        break;
      }
      case "ticketCount": {
        const activeFlag = !ticketCount;
        const activeValue = activeFlag ? "asc" : "desc";
        setTicketCount(activeFlag);
        setTicketCountVal(activeValue);
        params = { ...params, ticketCount: activeValue, page: 1 };
        break;
      }
      case "supportType":
        params = { ...params, supportType: value, page: 1 };
        break;
      case "page":
        params = { ...params, page: value };
        break;
      case "search":
        params = { ...params, search: value, page: 1 };
        break;
    }

    // Constructing the URL based on the parameters object
    const urlParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]: any) => {
      if (value) {
        urlParams.append(key, value);
      }
    });

    // Constructing the final URL
    const url = `/${currentLanguage}/ticket/list${urlParams.toString() ? `?${urlParams.toString()}` : ""}`;

    // Navigating to the final URL
    navigate(url);
  };

  useEffect(() => {
    // Function to handle debouncing
    const debounceFunction = () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current as any); // Cast to any
      }
      timeoutRef.current = setTimeout(getCompaniesList, 500);
    };
    // Trigger debounce function when search parameter changes
    debounceFunction();
    // Cleanup function to clear timeout when component unmounts
    return () => clearTimeout(timeoutRef.current);
  }, [location.search, searchParam, pageSize, shouldRefresh]);

  useEffect(() => {
    if (createdAssessPaginationRef.current && managePaginationRef != null) {
      createdAssessPaginationRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [managePaginationRef]);

  // to get assessment list
  async function getCompaniesList() {
    const search = searchParam?.trim() ?? "";
    const lastActive = lastActiveParam?.trim() ?? "";
    const userCount = userCountParam?.trim() ?? "";
    const assessmentCount = assessmentCountParam?.trim() ?? "";
    const ticketCount = ticketCountParam?.trim() ?? "";
    const supportType = supportTypeParam?.trim() ?? "";

    const response = await getSupportTickets(
      page,
      pageSize,
      search,
      lastActive,
      userCount,
      assessmentCount,
      ticketCount,
      supportType,
    );
    if (response?.status === 200) {
      setTicketList(response?.data?.data);
      setTotalCount(response?.data?.totalCount);
    } else {
      showToast(response?.data?.customMessage, "error");
    }
  }

  return (
    <>
      <div className="ticketListWrapper">
        <div className="container-fluid mb-4">
          <div className="row">
            <div className="col-md-12">
              <div className="CreatedAssessmentListTitle">
                <h2>
                  Support Tickets
                  <AssessmentButton
                    onClick={() => {
                      setShowCreateTicket(true);
                    }}
                    className="whiteBtn"
                  >
                    Create New
                  </AssessmentButton>
                </h2>
              </div>
            </div>
            <div className="col-md-12">
              <div
                className={`AssessmentListSearchSec ${showFilter ? "" : "addMargin"}`}
                ref={createdAssessPaginationRef}
              >
                <TemplateSearch
                  onChange={(e) => {
                    handleFilter({ type: "search", value: e });
                  }}
                  placeholder="Search Tickets"
                  handleKeyPress={handleKeyPressSearch}
                  iconName="searchList"
                  value={searchParam ?? ""}
                />
              </div>
            </div>
            <div className="col-md-12">
              {/* Filter HTML Start  */}

              {showFilter && (
                <div className={`${showFilter && "filterClicked"}`}>
                  <div className="typeSelectSec w-100">
                    <div className="typeSelectFilter">
                      <label htmlFor="" className="mainLabelFilter">
                        {/* {t("GENERAL.FILTERS")} */}
                        Status
                      </label>
                      <TypeSelect
                        options={defaultStatus}
                        placeholder={t("GENERAL.STATUS")}
                        value={status ?? ""}
                        onChange={(getValue: any) => {
                          handleFilter({ type: "status", value: getValue });
                        }}
                        loadingText={t("GENERAL.LOADING")}
                      />
                    </div>

                    <div className="typeSelectFilter">
                      <label htmlFor="" className="mainLabelFilter">
                        {/* {t("GENERAL.FILTERS")} */}
                        Support Type
                      </label>
                      <TypeSelect
                        options={supportType}
                        placeholder={t("GENERAL.STATUS")}
                        value={supportTypeParam ?? ""}
                        onChange={(getValue: any) => {
                          handleFilter({
                            type: "supportType",
                            value: getValue,
                          });
                        }}
                        loadingText={t("GENERAL.LOADING")}
                      />
                    </div>

                    <div className="typeSelectDateSec">
                      <label htmlFor="" className="mainLabelFilter">
                        <div className="arrowBtnSec">
                          <p>{t("COMPANY_LIST.LAST_ACTIVE")}</p>
                          <div
                            onClick={() => {
                              handleFilter({
                                type: "lastActive",
                                value: lastActiveVal,
                              });
                            }}
                            className="parentFilterBtn"
                          >
                            <h6 className="scoreBtn ">
                              {lastActive ? (
                                <UpArrowIcon isDisable={!lastActive} />
                              ) : (
                                <DownArrowIcon isDisable={!lastActive} />
                              )}
                            </h6>
                          </div>
                        </div>
                      </label>

                      <label htmlFor="" className="mainLabelFilter">
                        <div className="arrowBtnSec">
                          <p>{t("COMPANY_LIST.NO_OF_USERS")}</p>
                          <div
                            onClick={() => {
                              handleFilter({
                                type: "userCount",
                                value: userCountVal,
                              });
                            }}
                            className="parentFilterBtn"
                          >
                            <h6 className="scoreBtn ">
                              {userCount ? (
                                <UpArrowIcon isDisable={!userCount} />
                              ) : (
                                <DownArrowIcon isDisable={!userCount} />
                              )}
                            </h6>
                          </div>
                        </div>
                      </label>

                      <label htmlFor="" className="mainLabelFilter">
                        <div className="arrowBtnSec">
                          <p>{t("COMPANY_LIST.TOTAL_ASSESSMENT")}</p>
                          <div
                            onClick={() => {
                              handleFilter({
                                type: "assessmentCount",
                                value: assessmentCountVal,
                              });
                            }}
                            className="parentFilterBtn"
                          >
                            <h6 className="scoreBtn ">
                              {assessmentCount ? (
                                <UpArrowIcon isDisable={!assessmentCount} />
                              ) : (
                                <DownArrowIcon isDisable={!assessmentCount} />
                              )}
                            </h6>
                          </div>
                        </div>
                      </label>

                      <label htmlFor="" className="mainLabelFilter">
                        <div className="arrowBtnSec">
                          <p>{t("COMPANY_LIST.NO_OF_TICKETS")}</p>
                          <div
                            onClick={() => {
                              handleFilter({
                                type: "ticketCount",
                                value: ticketCountVal,
                              });
                            }}
                            className="parentFilterBtn"
                          >
                            <h6 className="scoreBtn ">
                              {ticketCount ? (
                                <UpArrowIcon isDisable={!ticketCount} />
                              ) : (
                                <DownArrowIcon isDisable={!ticketCount} />
                              )}
                            </h6>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              )}

              {/* Filter HTML End */}
            </div>
          </div>

          {/* <div className="row">
            <div className="col-12 py-4">
              <Filter
                filters={filters}
                handleFilter={handleFilter}
                filterCountValue={filterCountValue}
              />
            </div>
          </div> */}

          <div className="row">
            {ticketList.length ?
              ticketList.map((ticket: any) => {
                return (
                  <div className="col-md-12" key={Math.random()}>
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-2 text-center ">
                            <div className="companyBg">
                              <div className="profileSec">
                                <SupportTicketListingIcon />
                                <h4 className="text-center ticket-title">
                                  {ticket?.tokenServiceId}
                                </h4>
                                <h6 className="text-center">
                                  {ticket?.status}
                                  {/* {ticket.isActive ? "Active" : "Inactive"} */}
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-10 py-1">
                            <div className="AssessmentListCardDetails">
                              <div className="listItemSec">
                                <div className="ListCardTitleHeading">
                                  <div className="d-flex ListCardTitleHeading">
                                    <div>
                                      <h3
                                        className="cursorPointer"
                                      >
                                        {ticket?.subject}
                                      </h3>
                                      {/* <p>{ticket?.industry?.name}</p> */}
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: ` ${ticket?.description}`,
                                        }}
                                      >
                                        {/* {ticket?.description} */}
                                      </p>
                                    </div>
                                    <div className="listinIconDiv">
                                      <div
                                        className="cursorPointer"
                                        onClick={() => {
                                          navigate(
                                            `/${currentLanguage}/ticket/${ticket?.supportTicketId}`,
                                          );
                                        }}
                                      >
                                        <AssessmentListingIcon />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="bulletSec">
                                  <div className="">
                                    <h5>{ticket?.owner?.displayName}</h5>
                                    <h6>
                                      {convertDate(ticket?.createdAt, true) ??
                                        "-"}
                                    </h6>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="ListContentSkillsSec">
                              <div className="ListContentSkills decreaseGap">
                                <div className="w-100">
                                  <div className="w-100">
                                    <p>{t("GENERAL.LOCATION")}</p>
                                    <h5>{ticket?.totalApplicaitons ?? "-"}</h5>
                                  </div>
                                  <div>
                                    <p>{t("GENERAL.CREDIT")}</p>
                                    <h5>{ticket?.totalQuestions ?? "-"}</h5>
                                  </div>
                                </div>
                                <div className="w-100">
                                  <div className="w-100">
                                    <p>{t("GENERAL.ASSESSMENTS")}</p>
                                    <h5>{ticket?.totalResponses ?? "-"}</h5>
                                  </div>
                                  <div>
                                    <p>{t("GENERAL.OPEN_TICKETS")}</p>
                                    <h5>{ticket?.totalSelected ?? "-"}</h5>
                                  </div>
                                </div>
                                <div className="w-100">
                                  <div className="w-100">
                                    <p>{t("GENERAL.INTERNAL_RESOURCES")}</p>
                                    <h5>{ticket?.reviewed ?? "-"}</h5>
                                  </div>
                                  <div>
                                    <p>{t("GENERAL.LASTLOGIN")}</p>
                                    <h5>{"-"}</h5>
                                  </div>
                                </div>
                              </div>
                              <div className="RightSideArrowSec">
                                <AssessmentButton
                                  onClick={() => {
                                    navigate(
                                      `/${currentLanguage}/companyProfile/${ticket?.companyId}`,
                                    );
                                  }}
                                  className="whiteBtn"
                                >
                                  {t("GENERAL.VIEW")}
                                </AssessmentButton>
                                <br />
                              </div>
                            </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              ) :
              <DataNotAvailable />}
          </div>
          <div className="row">
            <div className="col-md-12">
              <Pagination
                className="pagination-bar"
                currentPage={page ?? 1}
                totalCount={totalCount || 0}
                pageSize={pageSize}
                onPageChange={(page: number) => {
                  handleFilter({ type: "page", value: page });
                  setManagePaginationRef(Math.random());
                }}
                ofLabel={t("GENERAL.OF")}
                resultLabel={t("GENERAL.RESULTS")}
                setPageSize={setPageSize}
              />
            </div>
          </div>
        </div>
      </div>

      <CreateTicketPopup
        showCreateTicket={showCreateTicket}
        label="Create New"
        isCrossBtn={true}
        setShouldReferesh={setShouldReferesh}
        handleClose={() => {
          setShowCreateTicket(false);
        }}
      />
    </>
  );
};

export default ListTicket;
