// Getter function to retrieve a value from localStorage
export function getSessionStorageItem(key: string, defaultValue = null) {
  try {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : defaultValue;
  } catch (error) {
    // Handle potential errors when retrieving the value
    console.error(`Error retrieving ${key} from localStorage: ${error}`);
    return defaultValue;
  }
}
