import apiClient from "../Connection/Connection";

export const getSupportTickets = async (
  currentPage: number,
  pageSize: number,
  search: string,
  lastActive: string,
  userCount: string,
  assessmentCount: string,
  ticketCount: string,
  supportType: string,
) => {
  try {
    const url =
      `/supportTicket/getAllSupportTicket?currentPage=${currentPage}&pageSize=${pageSize}` +
      (search ? `&search=${search}` : "") +
      (lastActive ? `&lastActive=${lastActive}` : "") +
      (userCount ? `&userCount=${userCount}` : "") +
      (assessmentCount ? `&assessmentCount=${assessmentCount}` : "") +
      (ticketCount ? `&ticketCount=${ticketCount}` : "") +
      (supportType ? `&supportType=${supportType}` : "");

    const res = await apiClient.get(url);
    return res?.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const getCompanyById = async (companyId: any) => {
  try {
    const res = await apiClient.get(
      `/company/getCompany?companyId=${companyId}`,
    );
    return res?.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const getCompaniesForTickets = async (search: any) => {
  try {
    const res = await apiClient.get(
      `/company/getAllCompanies?search=${search}`,
    );
    return res?.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const getTicketTypes = async () => {
  try {
    const res = await apiClient.get(
      "/supportTicketType/getAllSupportTicketType",
    );
    return res?.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const createTicket = async (data: any) => {
  try {
    const res = await apiClient.post(
      "/supportTicket/createSupportTicket",
      data,
    );
    return res?.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const getTicketById = async (ticketId: any) => {
  try {
    const res = await apiClient.get(
      `/supportTicket/getSupportTicket?supportTicketId=${ticketId}`,
    );
    return res?.data;
  } catch (error) {
    console.log("error", error);
  }
};
