function DownArrow() {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 7 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.34207 5.53846C3.96782 6.15385 3.03218 6.15385 2.65793 5.53846L0.131719 1.38462C-0.242534 0.769231 0.225282 -6.21213e-08 0.973789 0L6.02621 4.19319e-07C6.77472 4.8144e-07 7.24253 0.769231 6.86828 1.38462L4.34207 5.53846Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}

export default DownArrow;
