const CompanyAssessmentCountIcon = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.7419 7.96024V5.41145L27.3305 0H11.2903V7.90323H2.82258C1.26621 7.90323 0 9.16943 0 10.7258V28.7903C0 30.3467 1.26621 31.6129 2.82258 31.6129H9.59677V33.871H7.33871V35H27.6613V33.871H25.4032V31.6129H32.1774C33.7338 31.6129 35 30.3467 35 28.7903V10.7258C35 9.36306 34.0285 8.22274 32.7419 7.96024ZM27.0968 1.36274L31.3792 5.64516H27.0968V1.36274ZM12.4194 1.12903H25.9677V6.77419H31.6129V24.8387H12.4194V1.12903ZM24.2742 33.871H10.7258V31.6129H24.2742V33.871ZM33.871 28.7903C33.871 29.724 33.1111 30.4839 32.1774 30.4839H2.82258C1.88887 30.4839 1.12903 29.724 1.12903 28.7903V28.2258H33.871V28.7903ZM33.871 27.0968H1.12903V10.7258C1.12903 9.7921 1.88887 9.03226 2.82258 9.03226H11.2903V25.9677H32.7419V9.13613C33.3973 9.36984 33.871 9.99081 33.871 10.7258V27.0968Z"
        fill="black"
      />
      <path
        d="M5.14054 22.0564L5.12422 22.1362L5.19912 22.1683C5.54853 22.3181 5.93285 22.4017 6.33623 22.4017C6.73961 22.4017 7.12393 22.3181 7.47334 22.1683L7.54824 22.1362L7.53192 22.0564C7.41839 21.5009 6.92549 21.081 6.33623 21.081C5.74698 21.081 5.25408 21.5009 5.14054 22.0564ZM4.2154 21.4664L4.31872 21.5778L4.38019 21.4388C4.71287 20.6867 5.46308 20.1603 6.33623 20.1603C7.20938 20.1603 7.9596 20.6867 8.29228 21.4388L8.35375 21.5778L8.45706 21.4664C8.93686 20.9489 9.23796 20.2611 9.23796 19.4999C9.23796 18.0191 8.11958 16.8083 6.68682 16.6331L6.6546 16.8304C7.37447 16.9779 7.91727 17.6169 7.91727 18.3793C7.91727 19.2508 7.20781 19.9603 6.33623 19.9603C5.46465 19.9603 4.7552 19.2508 4.7552 18.3793C4.7552 17.6169 5.29799 16.9779 6.01786 16.8304L5.98565 16.6331C4.55288 16.8083 3.43451 18.0191 3.43451 19.4999C3.43451 20.2611 3.7356 20.9489 4.2154 21.4664ZM6.33623 15.6775C8.44337 15.6775 10.1586 17.3928 10.1586 19.4999C10.1586 21.6071 8.44337 23.3224 6.33623 23.3224C4.22909 23.3224 2.51382 21.6071 2.51382 19.4999C2.51382 17.3928 4.22909 15.6775 6.33623 15.6775ZM6.33623 19.0396C6.70077 19.0396 6.99658 18.7438 6.99658 18.3793C6.99658 18.0147 6.70077 17.7189 6.33623 17.7189C5.97169 17.7189 5.67589 18.0147 5.67589 18.3793C5.67589 18.7438 5.97169 19.0396 6.33623 19.0396Z"
        fill="black"
        stroke="white"
        strokeWidth="0.2"
      />
      <path
        d="M20.3227 4.4676V3.38712H14.6775V9.03228H20.3227V5.85462L22.3442 4.41058L21.6882 3.49212L20.3227 4.4676ZM19.1936 5.27429L18.1256 6.03695L17.3347 5.24607L16.5364 6.04429L18.0036 7.51147L19.1936 6.66131V7.90324H15.8065V4.51615H19.1936V5.27429Z"
        fill="black"
      />
      <path
        d="M20.3227 11.2417V10.1613H14.6775V15.8064H20.3227V12.6288L22.3442 11.1847L21.6882 10.2663L20.3227 11.2417ZM19.1936 12.0484L18.1256 12.8111L17.3347 12.0202L16.5364 12.8184L18.0036 14.2856L19.1936 13.4354V14.6774H15.8065V11.2903H19.1936V12.0484Z"
        fill="black"
      />
      <path
        d="M20.3227 18.016V16.9355H14.6775V22.5807H20.3227V19.403L22.3442 17.959L21.6882 17.0411L20.3227 18.016ZM19.1936 18.8227L18.1256 19.5854L17.3347 18.7945L16.5364 19.5927L18.0036 21.0599L19.1936 20.2097V21.4516H15.8065V18.0645H19.1936V18.8227Z"
        fill="black"
      />
      <path d="M29.355 7.9032H22.5808V9.03223H29.355V7.9032Z" fill="black" />
      <path d="M29.355 14.6774H22.5808V15.8065H29.355V14.6774Z" fill="black" />
      <path d="M29.355 21.4516H22.5808V22.5806H29.355V21.4516Z" fill="black" />
      <path d="M29.355 19.1936H22.5808V20.3226H29.355V19.1936Z" fill="black" />
      <path d="M29.355 12.4193H22.5808V13.5483H29.355V12.4193Z" fill="black" />
      <path d="M24.8389 5.64517H22.5808V6.7742H24.8389V5.64517Z" fill="black" />
    </svg>
  );
};

export default CompanyAssessmentCountIcon;
