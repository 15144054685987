function CopyVector() {
  return (
    <svg
      width="15"
      height="20"
      viewBox="0 0 15 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7871 5.89744L9.43548 0.225641C9.29952 0.0813436 9.11497 0.000184615 8.92258 0H6.53226C5.82648 0 5.14953 0.297159 4.65044 0.826113C4.15135 1.35506 3.87097 2.07246 3.87097 2.82051V4.10256H2.66129C1.95547 4.10256 1.27856 4.39972 0.779477 4.92868C0.280384 5.45762 0 6.17503 0 6.92308V17.1795C0 17.9275 0.280384 18.6449 0.779477 19.1738C1.27856 19.7029 1.95547 20 2.66129 20H9.43548C10.1413 20 10.8182 19.7029 11.3173 19.1738C11.8164 18.6449 12.0968 17.9275 12.0968 17.1795V15.8974H12.3387C13.0445 15.8974 13.7214 15.6003 14.2205 15.0713C14.7196 14.5424 15 13.8249 15 13.0769V6.41026C14.9924 6.21703 14.9164 6.03394 14.7871 5.89744ZM9.67742 2.62564L12.5226 5.64103H9.67742V2.62564ZM10.6452 17.1795C10.6452 17.5195 10.5177 17.8456 10.2909 18.0861C10.064 18.3265 9.75629 18.4615 9.43548 18.4615H2.66129C2.34046 18.4615 2.03278 18.3265 1.80592 18.0861C1.57906 17.8456 1.45161 17.5195 1.45161 17.1795V6.92308C1.45161 6.58306 1.57906 6.25696 1.80592 6.01653C2.03278 5.7761 2.34046 5.64103 2.66129 5.64103H3.87097V13.0769C3.87097 13.8249 4.15135 14.5424 4.65044 15.0713C5.14953 15.6003 5.82648 15.8974 6.53226 15.8974H10.6452V17.1795ZM12.3387 14.359H6.53226C6.21145 14.359 5.90371 14.2239 5.67687 13.9835C5.45003 13.7431 5.32258 13.4169 5.32258 13.0769V2.82051C5.32258 2.48049 5.45003 2.1544 5.67687 1.91397C5.90371 1.67354 6.21145 1.53846 6.53226 1.53846H8.22581V6.41026C8.22832 6.61345 8.30555 6.80756 8.44113 6.95125C8.57671 7.09493 8.7599 7.17683 8.95161 7.17949H13.5484V13.0769C13.5484 13.4169 13.4209 13.7431 13.1941 13.9835C12.9673 14.2239 12.6595 14.359 12.3387 14.359Z"
        fill="white"
      />
    </svg>
  );
}

export default CopyVector;
