const CompanySupportTicketIcon = () => {
  return (
    <svg
      width="45"
      height="38"
      viewBox="0 0 45 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.2 27.3H13.5C12.89 27.3 12.3049 27.0577 11.8736 26.6263C11.4423 26.195 11.2 25.61 11.2 25V4.3C11.2 3.69 11.4423 3.10499 11.8736 2.67365C12.3049 2.24232 12.89 2 13.5 2H34.2M34.2 27.3H41.1C41.7099 27.3 42.295 27.0577 42.7263 26.6263C43.1576 26.195 43.4 25.61 43.4 25V4.3C43.4 3.69 43.1576 3.10499 42.7263 2.67365C42.295 2.24232 41.7099 2 41.1 2H34.2M34.2 27.3V25M34.2 2V4.3"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 36.5H4.3C3.69 36.5 3.10499 36.2577 2.67365 35.8263C2.24232 35.395 2 34.81 2 34.2V13.5C2 12.89 2.24232 12.305 2.67365 11.8737C3.10499 11.4423 3.69 11.2 4.3 11.2H11.2M25 36.5H31.9C32.51 36.5 33.095 36.2577 33.5263 35.8263C33.9577 35.395 34.2 34.81 34.2 34.2V28.45M25 36.5V34.2M34.2 11.2023V11.2M34.2 18.1023V18.1"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CompanySupportTicketIcon;
