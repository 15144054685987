const CompanyUserIcon = () => {
  return (
    <svg
      width="31"
      height="39"
      viewBox="0 0 31 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3889 9.77778C23.3889 14.0733 19.9066 17.5556 15.6111 17.5556C11.3156 17.5556 7.83333 14.0733 7.83333 9.77778C7.83333 5.48223 11.3156 2 15.6111 2C19.9066 2 23.3889 5.48223 23.3889 9.77778Z"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6111 23.3889C8.09391 23.3889 2 29.4828 2 37H29.2222C29.2222 29.4828 23.1283 23.3889 15.6111 23.3889Z"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CompanyUserIcon;
