function AssessmentListingEditIcon() {
  return (
    <svg
      className="shadowSvg"
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="23" r="23" fill="white" />
      <circle cx="22.8623" cy="22.6208" r="19.2276" fill="white" />
      <circle
        cx="22.8623"
        cy="22.6208"
        r="18.7276"
        stroke="#35D4AE"
        strokeOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.6645 15.2255C28.6127 15.2073 28.5537 15.224 28.5193 15.2692C28.5118 15.2791 28.504 15.2888 28.496 15.2984L27.0032 17.0857C26.9984 17.0914 26.9936 17.097 26.9887 17.1026L22.7151 21.9464V23.7734L24.5246 23.3622L28.8927 18.1396L30.1951 16.5772C30.2169 16.5511 30.2402 16.5264 30.265 16.5032L28.6645 15.2255ZM27.2272 14.254C27.7804 13.5445 28.7777 13.3624 29.5458 13.8333C29.5747 13.851 29.6025 13.8704 29.6289 13.8916L31.3428 15.2598C31.3605 15.2739 31.3776 15.2888 31.3941 15.3043C31.7254 15.6169 31.9125 16.0538 31.9098 16.5104C31.9074 16.9455 31.733 17.3611 31.4263 17.6667L31.4548 17.6376C31.4702 17.6234 31.4115 17.6815 31.4263 17.6667L30.1515 19.196L25.7202 24.4943C25.7165 24.4986 25.7129 24.5029 25.7091 24.5072C25.5195 24.7246 25.2668 24.878 24.9854 24.945L24.9771 24.9469L22.6788 25.4692C22.6327 25.4797 22.5857 25.4861 22.5384 25.4885C21.8094 25.5251 21.1746 24.9938 21.0804 24.2682C21.0758 24.233 21.0735 24.1975 21.0735 24.162V21.7942C21.0735 21.7756 21.0741 21.7569 21.0754 21.7383C21.0956 21.4417 21.214 21.1604 21.4121 20.9391L21.4161 20.9347C21.4148 20.9362 21.4135 20.9376 21.4121 20.9391L25.7517 16.0206L27.2272 14.254ZM27.2272 14.254C27.2227 14.2596 27.2184 14.2653 27.214 14.271V14.254L27.2373 14.2418L27.2272 14.254Z"
        fill="#4D4D4D"
      />
      <path
        d="M22.9159 17.2684C23.3692 17.2684 23.7367 16.9 23.7367 16.4455C23.7367 15.9911 23.3692 15.6227 22.9159 15.6227V17.2684ZM15.7659 20.56H16.5867V20.5581L15.7659 20.56ZM15.7659 26.7316L16.5867 26.7334V26.7316H15.7659ZM19.8515 30.846L19.8495 31.6689H19.8515V30.846ZM25.9803 30.846V31.6689H25.9823L25.9803 30.846ZM30.0659 26.7316H29.245V26.7334L30.0659 26.7316ZM30.8867 22.6172C30.8867 22.1627 30.5192 21.7943 30.0659 21.7943C29.6125 21.7943 29.245 22.1627 29.245 22.6172H30.8867ZM27.1331 16.2434C26.96 15.8234 26.4801 15.6234 26.0611 15.7968C25.642 15.9702 25.4426 16.4514 25.6156 16.8714L27.1331 16.2434ZM29.7002 19.4664C30.1428 19.3683 30.4223 18.9291 30.3245 18.4853C30.2266 18.0416 29.7885 17.7613 29.3458 17.8594L29.7002 19.4664ZM18.8303 26.9379C18.377 26.9379 18.0095 27.3062 18.0095 27.7608C18.0095 28.2152 18.377 28.5837 18.8303 28.5837V26.9379ZM27.0025 28.5837C27.4558 28.5837 27.8233 28.2152 27.8233 27.7608C27.8233 27.3062 27.4558 26.9379 27.0025 26.9379V28.5837ZM22.9159 15.6227H19.8515L19.7332 16.4455L19.8515 17.2684H22.9159V15.6227ZM19.8495 15.6227C17.1348 15.6292 14.9393 17.8403 14.9451 20.5618L16.5867 20.5581C16.5829 18.7455 18.0453 17.2728 19.8534 17.2684L19.9613 16.9097L19.8495 15.6227ZM14.9451 20.56V26.7316H16.5867V20.56H14.9451ZM14.9451 26.7298C14.9393 29.4512 17.1348 31.6623 19.8495 31.6689L19.8534 30.0232C18.0453 30.0188 16.5829 28.546 16.5867 26.7334L14.9451 26.7298ZM19.8515 31.6689H25.9803V30.0232H19.8515V31.6689ZM25.9823 31.6689C28.6969 31.6623 30.8925 29.4512 30.8867 26.7298L29.245 26.7334C29.2489 28.546 27.7865 30.0188 25.9783 30.0232L25.9823 31.6689ZM30.8867 26.7316V22.6172H29.245V26.7316H30.8867ZM25.6156 16.8714C25.8289 17.3895 26.2641 18.1237 26.9145 18.691C27.5797 19.2713 28.5357 19.7245 29.7002 19.4664L29.3458 17.8594C28.8369 17.9722 28.3948 17.8006 27.9921 17.4494C27.5746 17.0852 27.2722 16.5812 27.1331 16.2434L25.6156 16.8714ZM18.8303 28.5837H27.0025V26.9379H18.8303V28.5837Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}

export default AssessmentListingEditIcon;
