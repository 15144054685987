import { useEffect, useRef, useState } from "react";
import { AssessmentButton, AttachmentIcon, Select, showToast, TextEditor } from "ui-components";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
// import AssessmentListingIcon from "../../utils/Images/AssessmentListingIcon";
import "./ViewTicket.scss";
import CreateTicketPopup from "./CreateTicketPopup";
// import SupportTicketListingIcon from "../../utils/Images/SupportTicketListingIcon";
import { getTicketById } from "../../service/SupportTicket/supportTicket";
import { convertDate, formatTimestamp } from "../../utils/Common/Constant";
import { getsupportTicketConversation, supportTicketConversationApi } from "../../service/Scope/Scope";
// import { setSessionStorageItem } from "../../utils/SessionStorageMethod/setSessionStorageItem";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

const ViewTicket = () => {
  const { t, } = useTranslation();
  // const currentLanguage = i18n.language;
  // const navigate = useNavigate();
  const statusArray = [
    { label: "NEW", value: "NEW" },
    { label: "IN_PROGRESS", value: "IN_PROGRESS" },
    { label: "ON_HOLD", value: "ON_HOLD" },
    { label: "CLOSED", value: "CLOSED" },
    { label: "RESOLVED", value: "RESOLVED" },
  ];

  const [showCreateTicket, setShowCreateTicket] = useState<boolean>(false);
  const [ticketDetails, setTicketDetails] = useState<any | null>(null);
  const [conversationMsg, setConversationMsg] = useState([])
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isNewMsg, setIsNewMsg] = useState(false)
  console.log(isNewMsg, "isnew000");

  const { ticketId } = useParams();
  useEffect(() => {
    getTicketByIdHandler();
  }, []);
  useEffect(() => {
    ticketDetails?.supportTicketId && getConversation()
  }, [ticketDetails])
  async function getTicketByIdHandler() {
    const response = await getTicketById(ticketId);
    if (response?.status === 200) {
      setTicketDetails(response?.data);
    } else {
      showToast(response?.data?.customMessage, "error");
    }
  }

  const getConversation = async () => {
    try {
      const res = await getsupportTicketConversation(ticketDetails?.supportTicketId)
      setConversationMsg(res?.data?.rows)
      console.log(res, "mesage")
    } catch (error) {
      console.error(error)
    }
  }
  // const handleMessageText = (
  //   content: any,
  //   _delta: any,
  //   _source: any,
  //   editor: any,
  // ) => {
  //   const currentLength = editor.getLength();
  //   setMsgLength(currentLength);
  //   console.log(content, "=content");

  //   setMessageText(content);
  //   setCountManageDesFlag(true);
  //   setSessionStorageItem("message", content);
  //   setSessionStorageItem("messageLength", currentLength);
  // };
  const ticketData = {
    description: "",
  };
  const ticketSchema = Yup.object().shape({
    description: Yup.string().required("Description is required."),
  });
  const handleMessageText = async (value: any,
    resetForm?: () => void,
    setSubmitting?: (data: boolean) => any) => {
    const data = {
      supportTicketId: ticketDetails?.supportTicketId,
      senderType: "USER",
      senderId: "4b5d0454-c298-4d1a-91f4-0d676174639c",
      receiverId: "6fa47b6c-3837-4333-984f-073ed7c309a6",
      message: value?.description,
      attachmentUrls: "testingS3URL.com"
    }
    setSubmitting && setSubmitting(true);
    try {
      const res = value?.description && await supportTicketConversationApi(data)
      setIsNewMsg(true)
      console.log(res);
      resetForm && resetForm();
      showToast(res?.customMessage, "success")
      setIsNewMsg(false)
    } catch (error) {
      console.error(error)
    }

  }
  const handleSelectCompanyLogo = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <div className="ticketListWrapper">
        <div className="container-fluid mb-4">
          <div className="row">
            <div className="col-md-12">
              <div className="CreatedAssessmentListTitle">
                <h2>
                  Support Tickets
                  <AssessmentButton
                    onClick={() => {
                      setShowCreateTicket(true);
                    }}
                    className="whiteBtn"
                  >
                    Create New
                  </AssessmentButton>
                </h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-8" key={Math.random()}>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="ticketBg m-3">
                        <div className="row">
                          <div className="col-md-12 mb-4 ticketHead">
                            <h5>
                              {" "}
                              <b>{ticketDetails?.subject}</b>
                            </h5>
                            <p dangerouslySetInnerHTML={{
                              __html: ` ${ticketDetails?.description}`,
                            }}></p>
                          </div>
                          <div className="col-md-3 mb-3">
                            <h6>Ticket ID</h6>
                            <p> #{ticketDetails?.tokenServiceId}</p>
                          </div>
                          <div className="col-md-3 mb-3">
                            <h6>Created by</h6>
                            <p>{ticketDetails?.adminUser?.name || "-"}</p>
                          </div>
                          <div className="col-md-3 mb-3">
                            <h6>Created date</h6>
                            <p>{convertDate(ticketDetails?.createdAt, true)} {formatTimestamp(ticketDetails?.createdAt)}</p>
                          </div>
                          <div className="col-md-3 mb-3">
                            <h6>Last updated</h6>
                            <p>{convertDate(ticketDetails?.updatedAt, true)} {formatTimestamp(ticketDetails?.updatedAt)}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mb-4">
                      <div className="ticketBodyBg m-3">
                        <div className="row">
                          <div className="col-md-12 mb-4 ticketHead">
                            <h5>
                              {" "}
                              <b>Messages {conversationMsg?.length ? `(${conversationMsg.length})` : ""}</b>
                            </h5>
                            {/* <div className="settingTextArea">
                              <TextEditor
                                value={messageText}
                                placeholder={""}
                                onChange={handleMessageText}
                              />
                            </div> */}

                            <Formik
                              initialValues={ticketData}
                              validationSchema={ticketSchema}
                              onSubmit={(
                                value,
                                { resetForm, setSubmitting },
                              ) => {
                                handleMessageText(
                                  value,
                                  resetForm,
                                  setSubmitting,
                                );
                              }}
                            >
                              {({
                                errors,
                                touched,
                                values,
                                setFieldValue,
                                isSubmitting,
                              }) => {
                                return (
                                  <Form>
                                    <div className="row">
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 mt-3">
                                        <Field name="description">
                                          {() => (
                                            <TextEditor
                                              placeholder={"New Message"}
                                              onChange={(newValue: any) => {
                                                setFieldValue(
                                                  "description",
                                                  newValue,
                                                );
                                              }}
                                              value={values.description}
                                            />
                                          )}
                                        </Field>
                                        {errors?.description &&
                                          touched?.description ? (
                                          <div className="errorMessage">
                                            {errors?.description}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="col-md-12 mt-2">
                                        <div className="attachTicketImg">
                                          <div
                                            className="image-circle"
                                            onClick={handleSelectCompanyLogo}                                          >
                                            <input
                                              type="file"
                                              ref={fileInputRef}
                                              style={{ display: "none" }}
                                              onChange={(e: any) => console.log(e)
                                              }
                                              accept="image/*"
                                            />
                                            <div className="ChooseImageIcon">
                                              <span>Add Attachments</span> &nbsp;
                                              <AttachmentIcon />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="messageSendBtn">
                                        <AssessmentButton
                                          onClick={handleMessageText}
                                          disabled={values?.description.length ? false : true || isSubmitting}
                                          type="submit"
                                          className="submitBtn"
                                        >
                                          {t("GENERAL.SAVE")}
                                        </AssessmentButton>
                                      </div>
                                    </div>
                                  </Form>
                                );
                              }}
                            </Formik>
                            {conversationMsg?.map((item: any, index: any) => (
                              <div className="display-messages" key={index}>
                                <span dangerouslySetInnerHTML={{
                                  __html: `${item?.message}`,
                                }}></span>
                              </div>
                            ))}
                          </div>
                          <div className="col-md-12"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4" key={Math.random()}>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 mb-5">
                      <div className="ticketBg m-3">
                        <div className="row">
                          <div className="col-md-12 mb-4 ticketHead">
                            <h5>
                              <b>Ticket details</b>
                            </h5>
                            <hr />
                          </div>
                          <div className="col-md-12 mb-3">
                            <h6>Status</h6>
                            <Select
                              placeholder="Status"
                              options={statusArray}
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <h6>Ticket type</h6>
                            <Select
                              placeholder="Ticket type"
                              options={[]}
                            />
                          </div>
                          <div className="col-md-12 mb-3">
                            <h6>Assign to</h6>
                            <Select
                              placeholder="Assignee"
                              options={[]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

      <CreateTicketPopup
        showCreateTicket={showCreateTicket}
        label="Create New"
        isCrossBtn={true}
        handleClose={() => {
          setShowCreateTicket(false);
        }}
      />
    </>
  );
};

export default ViewTicket;
