// import UserImage from "../../utils/Images/UserImage";
import { useEffect, useRef, useState } from "react";
import { getCompanies } from "../../service/Company/company";
import {
  AssessmentButton,
  Pagination,
  showToast,
  TemplateSearch,
  TypeSelect,
} from "ui-components";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { convertDate, handleKeyPressSearch } from "../../utils/Common/Constant";
import DownArrowIcon from "../../utils/Images/DownArrowIcon";
import AssessmentListingIcon from "../../utils/Images/AssessmentListingIcon";
import UpArrowIcon from "../../utils/Images/UpArrowIcon";
import EmailIcon from "../../utils/Images/EmailIcon";
import PhoneIcon from "../../utils/Images/PhoneIcon";
import "./CompanyList.scss";
import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable";
// import AssessmentListingEditIcon from "../../utils/Images/AssessmentListingEditIcon";
const CompanyList = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [lastActive, setLastActive] = useState(false);
  const [userCount, setUserCount] = useState(true);
  const [assessmentCount, setAssessmentCount] = useState(true);
  const [ticketCount, setTicketCount] = useState(true);
  const [lastActiveVal, setLastActiveVal] = useState("desc");
  const [userCountVal, setUserCountVal] = useState("asc");
  const [assessmentCountVal, setAssessmentCountVal] = useState("asc");
  const [ticketCountVal, setTicketCountVal] = useState("asc");

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("st");
  const searchParam = searchParams.get("search");
  const lastActiveParam = searchParams.get("lastActive");
  const userCountParam = searchParams.get("userCount");
  const assessmentCountParam = searchParams.get("assessmentCount");
  const ticketCountParam = searchParams.get("ticketCount");

  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const timeoutRef = useRef<any>(null);
  const [companyList, setCompanyList] = useState<any>([]);
  const [showFilter] = useState(true);
  const createdAssessPaginationRef = useRef<HTMLDivElement>(null);
  const defaultStatus = [
    { label: t("GENERAL.ALL"), value: "" },
    { label: t("GENERAL.ACTIVE"), value: "Active" },
    { label: t("GENERAL.INACTIVE"), value: "Inactive" },
  ];

  const handleFilter = ({ type, value }: any) => {
    let params = {
      st: status,
      lastActive: lastActiveVal,
      userCount: userCountVal,
      assessmentCount: assessmentCountVal,
      ticketCount: ticketCountVal,
      page: page,
      search: searchParam,
    };

    switch (type) {
      case "status":
        params = { ...params, st: value, page: 1 };
        break;
      case "lastActive": {
        const activeFlag = !lastActive;
        const activeValue = activeFlag ? "asc" : "desc";
        setLastActive(activeFlag);
        setLastActiveVal(activeValue);

        params = { ...params, lastActive: activeValue, page: 1 };
        break;
      }
      case "userCount": {
        const activeFlag = !userCount;
        const activeValue = activeFlag ? "asc" : "desc";
        setUserCount(activeFlag);
        setUserCountVal(activeValue);

        params = { ...params, userCount: activeValue, page: 1 };
        break;
      }

      case "assessmentCount": {
        const activeFlag = !assessmentCount;
        const activeValue = activeFlag ? "asc" : "desc";
        setAssessmentCount(activeFlag);
        setAssessmentCountVal(activeValue);

        params = { ...params, assessmentCount: activeValue, page: 1 };
        break;
      }

      case "ticketCount": {
        const activeFlag = !ticketCount;
        const activeValue = activeFlag ? "asc" : "desc";
        setTicketCount(activeFlag);
        setTicketCountVal(activeValue);

        params = { ...params, ticketCount: activeValue, page: 1 };
        break;
      }
      case "page":
        params = { ...params, page: value };
        break;
      case "search":
        params = { ...params, search: value, page: 1 };
        break;
    }

    // Constructing the URL based on the parameters object
    const urlParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]: any) => {
      if (value) {
        urlParams.append(key, value);
      }
    });

    // Constructing the final URL
    const url = `/${currentLanguage}/company_list/${urlParams.toString() ? `?${urlParams.toString()}` : ""}`;

    // Navigating to the final URL
    navigate(url);
  };

  useEffect(() => {
    // Function to handle debouncing
    const debounceFunction = () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current as any); // Cast to any
      }
      timeoutRef.current = setTimeout(getCompaniesList, 500);
    };
    // Trigger debounce function when search parameter changes
    debounceFunction();
    // Cleanup function to clear timeout when component unmounts
    return () => clearTimeout(timeoutRef.current);
  }, [location.search, searchParam, pageSize]);

  useEffect(() => {
    if (createdAssessPaginationRef.current && managePaginationRef != null) {
      createdAssessPaginationRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [managePaginationRef]);

  // to get assessment list
  async function getCompaniesList() {
    const search = searchParam?.trim() ?? "";
    const lastActive = lastActiveParam?.trim() ?? "";
    const userCount = userCountParam?.trim() ?? "";
    const assessmentCount = assessmentCountParam?.trim() ?? "";
    const ticketCount = ticketCountParam?.trim() ?? "";
    const isActive = status
    const response = await getCompanies(
      page,
      pageSize,
      search,
      lastActive,
      userCount,
      assessmentCount,
      ticketCount,
      isActive
    );
    if (response?.status === 200) {
      setCompanyList(response?.data?.data);
      setTotalCount(response?.data?.totalCount);
    } else {
      showToast(response?.data?.customMessage, "error");
    }
  }

  // const createAddress = (
  //   city: string,
  //   state: string,
  //   zipCode: string,
  //   country: string,
  // ) => {
  //   const addressParts = [];
  //   if (city) addressParts.push(city);
  //   if (state) addressParts.push(state);
  //   if (zipCode) addressParts.push(zipCode);
  //   if (country) addressParts.push(country);
  //   return addressParts.join(", ") || "-";
  // };

  return (
    <>
      <div className="companyListWrapper CreatedAssessmentList">
        <div className="container-fluid mb-4">
          <div className="row">
            <div className="col-md-12">
              <div className="CreatedAssessmentListTitle">
                <h2>{t("COMPANY_LIST.COMPANIES")}</h2>
              </div>
            </div>
            <div className="col-md-12">
              <div
                className={`AssessmentListSearchSec ${showFilter ? "" : "addMargin"}`}
                ref={createdAssessPaginationRef}
              >
                <TemplateSearch
                  onChange={(e) => {
                    handleFilter({ type: "search", value: e });
                  }}
                  placeholder={t("GENERAL.SEARCH_COMPANY")}
                  handleKeyPress={handleKeyPressSearch}
                  iconName="searchList"
                  value={searchParam ?? ""}
                />
              </div>
            </div>
            <div className="col-md-12">
              {/* Filter HTML Start  */}

              {showFilter && (
                <div className={`${showFilter && "filterClicked"}`}>
                  <div className="typeSelectSec w-100">
                    <div className="typeSelectFilter">
                      <label htmlFor="" className="mainLabelFilter">
                        {t("GENERAL.FILTERS")}
                      </label>
                      <TypeSelect
                        options={defaultStatus}
                        placeholder={t("GENERAL.STATUS")}
                        value={status ?? ""}
                        onChange={(getValue: any) => {
                          handleFilter({ type: "status", value: getValue });
                        }}
                        loadingText={t("GENERAL.LOADING")}
                      />
                    </div>

                    <div className="typeSelectDateSec">
                      <label htmlFor="" className="mainLabelFilter">
                        <div className="arrowBtnSec">
                          <p>{t("GENERAL.LASTLOGIN")}</p>
                          <div
                            onClick={() => {
                              handleFilter({
                                type: "lastActive",
                                value: lastActiveVal,
                              });
                            }}
                            className="parentFilterBtn"
                          >
                            <h6 className="scoreBtn ">
                              {lastActive ? (
                                <UpArrowIcon isDisable={!lastActive} />
                              ) : (
                                <DownArrowIcon isDisable={!lastActive} />
                              )}
                            </h6>
                          </div>
                        </div>
                      </label>

                      <label htmlFor="" className="mainLabelFilter">
                        <div className="arrowBtnSec">
                          <p>{t("COMPANY_LIST.NO_OF_USERS")}</p>
                          <div
                            onClick={() => {
                              handleFilter({
                                type: "userCount",
                                value: userCountVal,
                              });
                            }}
                            className="parentFilterBtn"
                          >
                            <h6 className="scoreBtn ">
                              {userCount ? (
                                <UpArrowIcon isDisable={!userCount} />
                              ) : (
                                <DownArrowIcon isDisable={!userCount} />
                              )}
                            </h6>
                          </div>
                        </div>
                      </label>

                      <label htmlFor="" className="mainLabelFilter">
                        <div className="arrowBtnSec">
                          <p>{t("COMPANY_LIST.TOTAL_ASSESSMENT")}</p>
                          <div
                            onClick={() => {
                              handleFilter({
                                type: "assessmentCount",
                                value: assessmentCountVal,
                              });
                            }}
                            className="parentFilterBtn"
                          >
                            <h6 className="scoreBtn ">
                              {assessmentCount ? (
                                <UpArrowIcon isDisable={!assessmentCount} />
                              ) : (
                                <DownArrowIcon isDisable={!assessmentCount} />
                              )}
                            </h6>
                          </div>
                        </div>
                      </label>

                      <label htmlFor="" className="mainLabelFilter">
                        <div className="arrowBtnSec">
                          <p>{t("COMPANY_LIST.NO_OF_TICKETS")}</p>
                          <div
                            onClick={() => {
                              handleFilter({
                                type: "ticketCount",
                                value: ticketCountVal,
                              });
                            }}
                            className="parentFilterBtn"
                          >
                            <h6 className="scoreBtn ">
                              {ticketCount ? (
                                <UpArrowIcon isDisable={!ticketCount} />
                              ) : (
                                <DownArrowIcon isDisable={!ticketCount} />
                              )}
                            </h6>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {/* Filter HTML End */}
            </div>
          </div>
          <div className="row">
            {companyList?.length ? (
              companyList?.map((company: any) => {
                return (
                  <div className="AssessmentListContentSec" key={Math.random()}>
                    <div className="AssessmentListCard">
                      <div className="AssessmentListCardImg ">
                        {company?.logo ? (
                          <div className="profileImage">
                            <img src={company?.logo} className="logo-img" />
                          </div>
                        ) : (
                          <AssessmentListingIcon />
                        )}
                        <h4> {company?.isActive ? "Active" : "Inactive"}</h4>
                        <h6>{convertDate(company?.createdAt, true) ?? "-"}</h6>
                      </div>
                      <div className="AssessmentListCardDetails">
                        <div className="ListCardDetailsTitleSec">
                          <div className="ListCardDetailsTitle">
                            <div className="listItemSec">
                              <div className="ListCardTitleHeading">
                                <div>
                                  <h3
                                    title="Hiring assessment invite flow-1"
                                    className="cursorPointer"
                                  >
                                    {company?.companyName}
                                  </h3>
                                  <p>{company?.industry?.name}</p>
                                  <p>
                                    {company?.email && (
                                      <a href={`mailto:${company.email}`} target="_blank" rel="noreferrer">
                                        <EmailIcon /> {company.email}
                                      </a>
                                    )}
                                    &nbsp; &nbsp;
                                    {company?.mobile && (
                                      <a href={`tel:${company.mobile}`}>
                                        <PhoneIcon /> {company?.mobile}
                                      </a>
                                    )}
                                  </p>
                                </div>
                                <div className="listinIconDiv" onClick={() => {
                                  navigate(`/${currentLanguage}/assessment_list/${company?.companyId}`, {
                                    state: {
                                      companyName: company?.companyName,
                                    },
                                  });
                                }}>
                                  <AssessmentListingIcon />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ListContentSkillsSec">
                          <div className="ListContentSkills decreaseGap">
                            <div className="w-100">
                              <div className="w-100">
                                <p>{t("GENERAL.LOCATION")}</p>
                                <h5>{company?.country || "-"}</h5>
                              </div>
                              <div>
                                <p
                                  className="clickable-fields"
                                  onClick={() =>
                                    navigate(
                                      `/${currentLanguage}/company_list/accounts`,
                                    )
                                  }
                                >
                                  {t("GENERAL.ACCOUNT")}
                                </p>
                                <h5>{company?.totalQuestions ?? "-"}</h5>
                              </div>
                            </div>
                            <div className="w-100">
                              <div className="w-100">
                                <p>
                                  {t("GENERAL.ASSESSMENTS")}
                                </p>
                                <h5>{company?.assessmentCount ?? "-"}</h5>
                              </div>
                            </div>
                            <div className="w-100">
                              <div className="w-100">
                                <p
                                  className="clickable-fields"
                                  onClick={() =>
                                    navigate(
                                      `/${currentLanguage}/company_list/openTickets`,
                                    )
                                  }
                                >
                                  {t("GENERAL.OPEN_TICKETS")}
                                </p>
                                <h5>{company?.openTicketCount ?? "-"}</h5>
                              </div>
                            </div>
                            <div className="w-100">
                              <div className="w-100">
                                <p>{t("GENERAL.LASTLOGIN")}</p>
                                <h5>{company?.owner?.lastLogin ?? "-"}</h5>
                              </div>
                            </div>
                          </div>
                          <div className="RightSideArrowSec">
                            <AssessmentButton
                              onClick={() => {
                                navigate(
                                  `/${currentLanguage}/companyProfile/${company?.companyId}`,
                                );
                              }}
                              className="whiteBtn"
                            >
                              {t("GENERAL.VIEW")}
                            </AssessmentButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <DataNotAvailable />
            )}
          </div>
          <div className="row">
            <div className="col-md-12">
              <Pagination
                className="pagination-bar"
                currentPage={page ?? 1}
                totalCount={totalCount || 0}
                pageSize={pageSize}
                onPageChange={(page: number) => {
                  handleFilter({ type: "page", value: page });
                  setManagePaginationRef(Math.random());
                }}
                ofLabel={t("GENERAL.OF")}
                resultLabel={t("GENERAL.RESULTS")}
                setPageSize={setPageSize}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyList;
