import { handleError } from "../../utils/Common/Constant";
import apiClient from "../Connection/Connection";

//  to get created assessment list
export const getCreatedAssessList = async (data: any) => {
  try {
    const res = await apiClient.post("company/companyGetAssessmentList", data);
    return res;
  } catch (error) {
    handleError(error);
  }
};

//  to get created company GetAssessmentApplicantList
export const companyGetAssessmentApplicantList = async (data: any) => {
  try {
    const res = await apiClient.post(
      "company/companyGetAssessmentApplicantList",
      data,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};

//  to get company Assessment Applicant Count
export const companyGetAssessmentApplicantCount = async (
  assessmentId: string,
  search?: string,
) => {
  try {
    const res = await apiClient.get(
      `company/companyGetAssessmentApplicantCount?assessmentId=${assessmentId}${search ? `&search=${search}` : ""}`,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};

//  to get applicant assessment response
export const companyGetApplicantAssessmentResponse = async (
  applicantId: string,
  assessmentId: string,
) => {
  try {
    const res = await apiClient.get(
      `company/companyGetApplicantAssessmentResponse?applicantId=${applicantId}&assessmentId=${assessmentId}`,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};
