import { handleError } from "../../utils/Common/Constant";
import apiClient from "../Connection/Connection";

// company login
export const adminLogin = async (data: any) => {
    try {
      const res = await apiClient.post("/auth/login", data);
      return res?.data;
    } catch (error) {
      handleError(error);
    }
  };
  