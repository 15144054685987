import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18nConfig";
import ErrorBoundary from "./utils/ErrorBoundary/ErrorBoundary";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "ui-components";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <App />
          <ToastContainer />
        </BrowserRouter>
      </I18nextProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);
