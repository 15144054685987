import React, { useState } from "react";
import "./CreateTicket.scss";
// import * as Yup from "yup";
import {
  AttachmentIcon,
  Button,
  CheckboxRadio,
  InputField,
  Select,
  TextEditor,
} from "ui-components";
// import { t } from "i18next";

const CreateTicket = () => {
  const [supportType, setSupportType] = useState({
    billingSupport: false,
    technicalSupport: true,
  });
  const handleSupport = (name: string) => {
    if (name === "billingSupport") {
      setSupportType((prev: any) => ({
        ...prev,
        billingSupport: true,
        technicalSupport: false,
      }));
    } else {
      setSupportType((prev: any) => ({
        ...prev,
        billingSupport: false,
        technicalSupport: true,
      }));
    }
  };

  const options = [{ label: "c1", value: "c1" }];
  return (
    <>
      <div className="ticketWrapper">
        <div className="container-fluid mt-4 mb-4">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="card">
                <div className="card-body">
                  <div className="newTicketForm">
                    <form>
                      <div className="row">
                        <div className="col-md-12 text-center mb-3">
                          <h5 className="formHeading">CREATE A NEW TICKET</h5>
                        </div>
                        <div className="col-md-12 ">
                          <label className="formLabel" htmlFor="">
                            Select Support Type
                          </label>
                        </div>
                        <div className="col-md-6">
                          <CheckboxRadio
                            name="billingSupportType"
                            type={"radio"}
                            label={"Accounting and billing Support"}
                            checked={supportType?.billingSupport}
                            onChange={() => handleSupport("billingSupport")}
                            // onChange=(checked: boolean) => void;
                          />
                        </div>
                        <div className="col-md-6">
                          <CheckboxRadio
                            name="technicalSupportType"
                            type={"radio"}
                            label={"Technical Support"}
                            checked={supportType?.technicalSupport}
                            onChange={() => handleSupport("technicalSupport")}
                          />
                        </div>
                        <div className="col-md-12 mt-3">
                          <label className="formLabel" htmlFor="">
                            Select Company
                          </label>
                          <Select placeholder="select" options={options} />
                          {/* <select
                              className="form-select"
                              aria-label="Default select example"
                            >
                              <option selected>Open this select menu</option>
                              <option value="1">One</option>
                              <option value="2">Two</option>
                              <option value="3">Three</option>
                            </select> */}
                        </div>
                      </div>
                      {/* <div className="row">
                            <div className="col-md-6 mt-3">
                              <label className="formLabel" htmlFor="">
                                Service
                              </label>
                              <Select
                                options={[
                                  {
                                    value: "any",
                                    label: "string",
                                  },
                                ]}
                              />
                            </div>
                            <div className="col-md-6 mt-3">
                              <label className="formLabel" htmlFor="">
                                Severity
                              </label>
                              <Select
                                options={[
                                  {
                                    value: "any",
                                    label: "string",
                                  },
                                ]}
                              />
                            </div>
                          </div> */}
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <label className="formLabel" htmlFor="">
                            Subject
                          </label>
                          <InputField
                            placeholder={"Subject"}
                            onChange={() => {}}
                            value={""}
                          />
                        </div>
                        <div className="col-md-12 mt-3">
                          <label className="formLabel" htmlFor="">
                            Description
                          </label>
                          <TextEditor
                            value={""}
                            placeholder={""}
                            onChange={"handleDescription"}
                          />
                        </div>
                        <div className="col-md-12 mt-2">
                          <label className="formLabel" htmlFor="">
                            Files
                          </label>
                          <div className="attachTicketImg">
                            <input
                              id={"fileInput"}
                              type="file"
                              accept="image/*"
                              // style={{ display: "none" }}
                            />
                            <span>
                              Attachment <AttachmentIcon />
                            </span>
                          </div>
                        </div>
                        <div className="text-center">
                          <Button type="submit">Submit</Button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-header">
                  <b>NEW TICKETS</b>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="newTicketsSec">
                        <div className="row p-1">
                          <div className="col-md-9 newTicketsContent">
                            Add drag and drop config import closes
                            <span className="bg-primary">New</span>
                          </div>
                          <div className="col-md-3 ">
                            <div className="text-right">#A-0000001</div>
                          </div>
                          <div className="col-md-12 mt-2">
                            <p className="ticketDecription">
                              Lorem ipsum, dolor sit amet consectetur
                              adipisicing elit. Delectus optio sapiente ipsum
                              voluptatem mollitia <a href="#">...read more</a>
                            </p>
                          </div>
                        </div>

                        <div className="row p-1">
                          <div className="col-md-6">
                            <h6>Service</h6>
                            <p>Create Assessment</p>
                          </div>
                          <div className="col-md-6">
                            <h6>Severity</h6>
                            <p>High</p>
                          </div>
                          <div className="col-md-6">
                            <h6>Created By</h6>
                            <p>Nitin</p>
                          </div>
                          <div className="col-md-6">
                            <h6>Assigned</h6>
                            <p>Vadood</p>
                          </div>
                          <div className="col-md-6">
                            <h6>Created At</h6>
                            <p>24 Apr, 2024 10:20:20</p>
                          </div>
                        </div>
                        {/* // this is commented code */}
                        <div className="newTicketsContent">
                          <div className="d-flex">
                            <div>
                              <i className="bi bi-arrow-down-right-circle"></i>
                            </div>
                            <div>
                              <div>
                                <div>sds</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* //  end */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateTicket;
