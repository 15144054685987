import apiClient from "../Connection/Connection";

export const companyGetAssessments = async (postData:unknown) => {
  try {
    const res = await apiClient.post("/company/companyGetAssessmentList", postData);
    return res?.data;
  } catch (error) {
    console.log("error", error);
  }
  
  // try {
  //   const res = await apiClient.get("company");
  //   return res?.data;
  // } catch (error) {
  //   console.log("error", error);
  // }
};
