import apiClient from "../Connection/Connection";

export const getCompanies = async (
  currentPage: number,
  pageSize: number,
  search: string,
  lastActive: string,
  userCount: string,
  assessmentCount: string,
  ticketCount: string,
  isActive: any,
) => {
  const active = isActive === "Active" ? true : false;
  try {
    const url =
      `/company/getAllCompanies?currentPage=${currentPage}&pageSize=${pageSize}` +
      (search ? `&search=${search}` : "") +
      (lastActive ? `&lastActive=${lastActive}` : "") +
      (userCount ? `&userCount=${userCount}` : "") +
      (assessmentCount ? `&assessmentCount=${assessmentCount}` : "") +
      (ticketCount ? `&ticketCount=${ticketCount}` : "") +
      (isActive ? `&isActive=${active}` : "");

    const res = await apiClient.get(url);
    return res?.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const getCompanyById = async (companyId: any) => {
  try {
    const res = await apiClient.get(
      `/company/getCompany?companyId=${companyId}`,
    );
    return res?.data;
  } catch (error) {
    console.log("error", error);
  }
};

export const getCompaniesForTickets = async () => {
  try {
    const res = await apiClient.get("/company/getAllCompanies");
    return res?.data;
  } catch (error) {
    console.log("error", error);
  }
};
