const CompanyCreditIcon = () => {
  return (
    <svg
      width="31"
      height="39"
      viewBox="0 0 31 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.443 34.3864C26.4554 34.3769 26.4705 34.3717 26.4862 34.3717C26.5018 34.3717 26.5169 34.3769 26.5293 34.3864L29.1955 36.982C29.1955 36.982 29.2896 36.982 29.2896 36.9271V2H2.0003V36.8644C1.99909 36.8767 2.00154 36.8891 2.00736 36.9C2.01317 36.911 2.02209 36.9199 2.03299 36.9258C2.04389 36.9317 2.05628 36.9342 2.0686 36.933C2.08093 36.9319 2.09263 36.9271 2.10225 36.9193L5.53693 34.3394C5.54806 34.3305 5.56189 34.3256 5.57614 34.3256C5.59039 34.3256 5.60422 34.3305 5.61535 34.3394L9.16766 36.9507C9.17931 36.9617 9.19474 36.9678 9.21079 36.9678C9.22683 36.9678 9.24227 36.9617 9.25392 36.9507L12.3906 34.3394C12.3906 34.3394 12.4455 34.3394 12.4769 34.3394L16.0213 36.9428C16.033 36.9538 16.0484 36.96 16.0645 36.96C16.0805 36.96 16.096 36.9538 16.1076 36.9428L19.4011 34.3864C19.4141 34.3748 19.4308 34.3684 19.4482 34.3684C19.4655 34.3684 19.4823 34.3748 19.4952 34.3864L23.0319 36.982C23.0448 36.9936 23.0616 37 23.0789 37C23.0963 37 23.113 36.9936 23.126 36.982L26.2627 34.3943"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M5.23108 18.7892H15.6606"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M5.23108 14.6017H17.7543"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M5.23108 6.15613H18.8208"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M5.23108 10.1633H16.5467"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M22.7102 18.6637H26.0586"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M5.23108 23.259H17.7543"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M23.3767 23.1413H26.0586"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M22.7102 27.768H26.0586"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M22.7102 14.4762H26.0586"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M23.3767 10.1633H26.0586"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <path
        d="M22.7102 6.15613H26.0586"
        stroke="black"
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default CompanyCreditIcon;
