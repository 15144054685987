import { useEffect, useState } from "react";
import "./CompanyProfile.scss";
import { useTranslation } from "react-i18next";
import CompanyDetailsIcon from "../../utils/Images/CompanyDetailsIcon";
// import CompanyBranchDetailsIcon from "../../utils/Images/CompanyBranchDetailsIcon";
import CompanyOwnerDetailsIcon from "../../utils/Images/CompanyOwnerDetailsIcon";
import CompanyLocationIcon from "../../utils/Images/CompanyLocationIcon";
import { showToast } from "ui-components";
import { useParams } from "react-router-dom";
import CompanyAssessmentCountIcon from "../../utils/Images/CompanyAssessmentCountIcon";
import CompanyUserIcon from "../../utils/Images/CompanyUserIcon";
import CompanyCreditIcon from "../../utils/Images/CompanyCreditIcon";
import CompanyCalenderIcon from "../../utils/Images/CompanyCalenderIcon";
import CompanySupportTicketIcon from "../../utils/Images/CompanySupportTicketIcon";
import { getCompanyById } from "../../service/Company/company";
import LinkedinIcon from "../../utils/Images/LinkedinIcon";
import FacebookIcon from "../../utils/Images/FacebookIcon";
import InstagramIcon from "../../utils/Images/InstagramIcon";
import { checkObjectValues } from "../../utils/Common/Constant";
import BackArrow from "../../utils/Images/BackArrow";

const CompanyProfile = () => {
  interface Industry {
    industriesId: string;
    name: string;
  }

  interface Branch {
    branchId: string;
    branchName: string;
    branchContactPerson: string;
    branchEmail: string;
    branchLocation: string;
    address: string;
  }

  interface Owner {
    companyUserId: string;
    profilePic: string | null;
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    designation: string;
  }
  interface socialLinks {
    facebook: string;
    instagram: string;
    linkedIn: string;
  }
  interface CompanyData {
    companyId: string;
    website: string | null;
    companyName: string;
    isActive: boolean;
    domain: string | null;
    logo: string | null;
    industry: Industry;
    branches: Branch[];
    contactPersonDetails: Owner;
    address: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    zipCode: string | null;
    lat: string | null;
    long: string | null;
    assessmentCount: string;
    supportTicketCount: string;
    userCount: string;
    credit: string;
    mobile: string;
    lastLogin: string;
    socialLink: socialLinks[];
    email: string
  }

  const { t } = useTranslation();
  const { companyId } = useParams();
  const [companyDetails, setCompanyDetails] = useState<CompanyData | null>(
    null,
  );
  const [isSocialValues, setIsSocialValues] = useState(false);
  useEffect(() => {
    if (companyId) getCompanyByIdHandler();
  }, []);

  async function getCompanyByIdHandler() {
    const response = await getCompanyById(companyId);
    if (response?.status === 200) {
      setCompanyDetails(response?.data);
      const haveSocialValues = checkObjectValues(response?.data?.socialLink[0]);
      setIsSocialValues(haveSocialValues);
    } else {
      showToast(response?.data?.customMessage, "error");
    }
  }

  return (
    <div className="companyProfileWrapper">
      <div className="backArrow" onClick={() => window.history.back()}>
        <BackArrow />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="card companyProfileCard">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="companyLogo">
                      {/* <div className="pageTitle">
                        <h2>{t("COMPANY_LIST.COMPANIES")}</h2>
                      </div> */}
                      {/* <div>
                        <AssessmentButton
                          onClick={() => {
                            navigate("/CompanyProfile");
                          }}
                          className="whiteBtn"
                        >
                          {t("GENERAL.RESTRICT_ACCESS")}
                        </AssessmentButton>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex col-md-12 align-items-center gap-3">
                    {/* <h3>Logo</h3> */}
                    {companyDetails?.logo && (
                      <img src={companyDetails?.logo || ""} width={"10%"} />
                    )}
                    <h6 className="comapnyName"> {companyDetails?.companyName} </h6>
                  </div>
                </div>
                {/* Company Details */}
                <div className="row detailContent">
                  <div className="col-md-12 detailContentHead">
                    <div className="detailContentHeadTile">
                      <div>
                        <CompanyDetailsIcon />
                      </div>
                      <h5>{t("GENERAL.COMPANY_DETAILS")}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 detailContentValues company-col ">
                    <label htmlFor=""> {t("GENERAL.INDUSTRY_TYPE")} </label>
                    <h6> {companyDetails?.industry?.name} </h6>
                  </div>
                  <div className="col-md-4 detailContentValues company-col">
                    <label htmlFor="">{t("GENERAL.COMPANY_NAME")}</label>
                    <h6> {companyDetails?.companyName} </h6>
                  </div>
                  <div className="col-md-4 detailContentValues company-col">
                    <label htmlFor=""> {t("GENERAL.WEBSITE")} </label>
                    <h6>
                      <a
                        href={companyDetails?.website || ""}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        {companyDetails?.website}
                      </a>
                    </h6>
                  </div>
                  <div className="col-md-4 detailContentValues company-col">
                    <label htmlFor=""> {t("GENERAL.EMAIL_ID")} </label>
                    <h6> {companyDetails?.email} </h6>
                  </div>
                  <div className="col-md-4 detailContentValues company-col">
                    <label htmlFor=""> {t("GENERAL.MOBILE_NO")} </label>
                    <h6> {companyDetails?.mobile} </h6>
                  </div>
                  <div className="col-md-4 detailContentValues company-col">
                    <label htmlFor=""> {t("GENERAL.SOCIAL_LINKS")} </label>
                    <div className="SocialLiks-icon">
                      {isSocialValues ? (
                        <>
                          {companyDetails?.socialLink[0].instagram && (
                            <a
                              href={companyDetails?.socialLink[0].instagram}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <InstagramIcon />
                            </a>
                          )}
                          {companyDetails?.socialLink[0].linkedIn && (
                            <a
                              href={companyDetails?.socialLink[0].linkedIn}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <LinkedinIcon />
                            </a>
                          )}
                          {companyDetails?.socialLink[0].facebook && (
                            <a
                              href={companyDetails?.socialLink[0].facebook}
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              <FacebookIcon />
                            </a>
                          )}
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                </div>

                {/* Company Location */}
                <div className="row detailContent">
                  <div className="col-md-12 detailContentHead">
                    <div className="detailContentHeadTile">
                      <div>
                        <CompanyLocationIcon />
                      </div>
                      <h5> {t("GENERAL.COMPANY_LOCATION")}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 detailContentValues company-col">
                    <label htmlFor=""> {t("GENERAL.ADDRESS")} </label>
                    <h6>{companyDetails?.address || "-"} </h6>
                  </div>
                  <div className="col-md-4 detailContentValues company-col">
                    <label htmlFor=""> {t("GENERAL.COUNTRY")} </label>
                    <h6>{companyDetails?.country || "-"} </h6>
                  </div>
                  <div className="col-md-4 detailContentValues company-col">
                    <label htmlFor=""> {t("GENERAL.STATE")} </label>
                    <h6> {companyDetails?.state || "-"} </h6>
                  </div>
                  <div className="col-md-4 detailContentValues company-col">
                    <label htmlFor=""> {t("GENERAL.CITY")} </label>
                    <h6> {companyDetails?.city || "-"} </h6>
                  </div>
                  <div className="col-md-4 detailContentValues company-col">
                    <label htmlFor=""> {t("GENERAL.ZIP_CODE")} </label>
                    <h6> {companyDetails?.zipCode || "-"} </h6>
                  </div>
                  <div className="col-md-4 detailContentValues company-col">
                    <label htmlFor=""> {t("GENERAL.GEOCOORDS")} </label>
                    <h6> {companyDetails?.lat || "-"} </h6>
                  </div>
                </div>

                {/* Owner Details */}
                <div className="row detailContent">
                  <div className="col-md-12 detailContentHead">
                    <div className="detailContentHeadTile">
                      <div>
                        <CompanyOwnerDetailsIcon />
                      </div>
                      <h5> {t("GENERAL.CONTACT_PERSON_DETAILS")}</h5>
                    </div>
                  </div>
                  <div className="col-md-4 detailContentValues company-col">
                    <label htmlFor=""> {t("GENERAL.NAME")} </label>
                    <h6>{`${companyDetails?.contactPersonDetails?.firstName} ${companyDetails?.contactPersonDetails?.lastName}` || "-"}</h6>
                  </div>
                  <div className="col-md-4 detailContentValues company-col">
                    <label htmlFor=""> {t("GENERAL.EMAIL_ID")} </label>
                    <h6> {companyDetails?.contactPersonDetails?.email || "-"} </h6>
                  </div>
                  <div className="col-md-4 detailContentValues company-col">
                    <label htmlFor=""> {t("GENERAL.MOBILE_NO")} </label>
                    <h6> {companyDetails?.contactPersonDetails?.mobile || "-"} </h6>
                  </div>
                  <div className="col-md-4 detailContentValues company-col">
                    <label htmlFor=""> {t("GENERAL.DESIGNATION")} </label>
                    <h6> {companyDetails?.contactPersonDetails?.designation || "-"} </h6>
                  </div>
                </div>

                {/* Branch Details */}
                {/* <div className="row detailContent">
                  <div className="col-md-12 detailContentHead">
                    <div className="detailContentHeadTile">
                      <div>
                        <CompanyBranchDetailsIcon />
                      </div>
                      <h5> Branch Details</h5>
                    </div>
                  </div>
                  <div className="col-md-4 detailContentValues">
                    <label htmlFor=""> Branch Name</label>
                    <h6> {companyDetails?.branches[0]?.branchName || "-"} </h6>
                  </div>
                  <div className="col-md-4 detailContentValues">
                    <label htmlFor=""> Contact person </label>
                    <h6>
                      {" "}
                      {companyDetails?.branches[0]?.branchContactPerson ||
                        "-"}{" "}
                    </h6>
                  </div>
                  <div className="col-md-4 detailContentValues">
                    <label htmlFor=""> Email ID </label>
                    <h6> {companyDetails?.branches[0]?.branchEmail || "-"} </h6>
                  </div>
                  <div className="col-md-4 detailContentValues">
                    <label htmlFor=""> Location </label>
                    <h6>
                      {" "}
                      {companyDetails?.branches[0]?.branchLocation || "-"}{" "}
                    </h6>
                  </div>
                  <div className="col-md-4 detailContentValues">
                    <label htmlFor=""> Address </label>
                    <h6> {companyDetails?.branches[0]?.address || "-"} </h6>
                  </div>
                </div> */}

                {/* Other Details */}
                <div className="row detailContent">
                  <div className="col-md-12 detailContentHead">
                    <hr />
                  </div>
                  <div className="col-md-4 detailContentValues ">
                    <CompanyAssessmentCountIcon />
                    <div>
                      <label htmlFor="">{t("GENERAL.ASSESSMENT_COUNT")}</label>
                      <h6> {companyDetails?.assessmentCount || "-"} </h6>
                    </div>
                  </div>
                  <div className="col-md-4 detailContentValues">
                    <CompanyUserIcon />
                    <div>
                      <label htmlFor="">{t("GENERAL.USERS_COUNT")}</label>
                      <h6> {companyDetails?.userCount || "-"} </h6>
                    </div>
                  </div>
                  <div className="col-md-4 detailContentValues">
                    <CompanyCreditIcon />
                    <div>
                      <label htmlFor="">{t("GENERAL.CREDITS")} </label>
                      <h6> {companyDetails?.credit || "-"} </h6>
                    </div>
                  </div>
                  <div className="col-md-4 detailContentValues">
                    <CompanyCalenderIcon />
                    <div>
                      <label htmlFor=""> {t("GENERAL.LAST_LOGIN_DATE")} </label>
                      <h6> {companyDetails?.lastLogin || "-"} </h6>
                    </div>
                  </div>
                  <div className="col-md-4 detailContentValues">
                    <CompanySupportTicketIcon />
                    <div>
                      <label htmlFor=""> {t("GENERAL.SUPPORT_TICKETS")} </label>
                      <h6> {companyDetails?.supportTicketCount || "-"} </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfile;
